import { FC } from "react";
import Spinner from "../../components/spinner/Spinner";
import "./style.scss";
import { Member } from "../../utils/type";
import { GET_ALL_MEMBERS } from "../queries/queries";
import { useQuery } from "@apollo/client";

const getAllMember = (data: Member[]) => {
  if (data.length === 0) {
    return [];
  }
  const allMembers: Member[] = [];
  data.map((memeber: any) => {
    allMembers.push({
      id: memeber.node.databaseId,
      name: memeber.node.nom,
      occuppant: memeber.node.occuppant,
    });
  });
  return allMembers;
};
const Membre: FC = () => {
  const { loading, error, data } = useQuery(GET_ALL_MEMBERS);
  const members = data ? getAllMember(data.members.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div className="teamPage">
      <h2 style={{ padding: 0, fontWeight: 800, fontSize: "28px", lineHeight: "22px" }}>
        Les membres du bureau de l'ARIC
      </h2>
      <div className="teams">
        {members?.map((membre: any, i: number) => (
          <div>
            <h3>{membre.name}</h3>
            <span>{membre.occupant}</span>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default Membre;

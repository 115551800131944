import { FC, useState } from "react";

import Slider from "react-slick";
import header1 from "../../assets/img/header1.svg";
import header2 from "../../assets/img/header2.svg";
import header3 from "../../assets/img/header3.svg";
import { ActiviteTypeTexonomy } from "../../utils/type";
import { Navbar } from "./Navbar";
import "./style.scss";
type Props = {
  allActiviteType: ActiviteTypeTexonomy[];
};

export const Header: FC<Props> = ({ allActiviteType }) => {
  const [navBarOpen, setNavbarOpen] = useState(false);

  const show = () => {
    setNavbarOpen(!navBarOpen);
  };
  const bannerCarouselSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="main-header">
      <div className="stickynavbar">
        <Navbar navBarOpen={navBarOpen} show={show} allActiviteType={allActiviteType} />
      </div>
      <div>
        <div className="banner-slider-wrapper">
          <Slider {...bannerCarouselSettings}>
            <img src={header3} alt="header" />
            <img src={header2} alt="header" />
            <img src={header1} alt="header" />
          </Slider>
        </div>
        <div className="header_wave">
          <svg
            width="100%"
            height="59"
            viewBox="0 0 1516 59"
            preserveAspectRatio="none"
            fill="#DFF3FD"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.700195 58.5V42C51.1002 42 103.8 2 154.2 2C204.6 2 255 42 305.4 42C355.8 42 406.2 2 456.6 2C507 2 557.401 42 607.801 42C658.201 42 708.601 2 759.001 2C809.401 2 859.801 42 910.201 42C960.601 42 1011 2 1061.4 2C1111.8 2 1162.2 42 1212.6 42C1263 42 1313.4 2 1363.8 2C1414.2 2 1465.3 42 1515.7 42V58.5"
              fill="#DFF3FD"
            />
            <path
              d="M2.2002 42C52.6002 42 103 2 153.4 2C203.8 2 254.2 42 304.6 42C355 42 405.4 2 455.8 2C506.2 2 556.6 42 607 42C657.4 42 707.8 2 758.2 2C808.6 2 859 42 909.4 42C959.8 42 1010.2 2 1060.6 2C1111 2 1161.4 42 1211.8 42C1262.2 42 1312.6 2 1363 2C1413.4 2 1463.8 42 1514.2 42"
              stroke="#41A592"
              strokeWidth="3"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>{" "}
      </div>
      <div className="title">
        <h1>Association réadaptation infantile clemenceau</h1>
        <p>La vie du service enfants adolescents de l’iurc</p>
      </div>
    </div>
  );
};

import { FC } from "react";
import "./style.scss";
import { Projet } from "../../utils/type";
import ProjetComponent from "../../components/projet/Projet";
import Spinner from "../../components/spinner/Spinner";
import { GET_ALL_PROJET } from "../../pags/queries/queries";
import { useQuery } from "@apollo/client";

const getAllAProjets = (data: Projet[]) => {
  if (data.length === 0) {
    return [];
  }
  const allprojet: Projet[] = [];
  data.map((projet: any) => {
    allprojet.push({
      id: projet.node.databaseId,
      title: projet.node.title,
      body: projet.node.content,
      status: projet.node.statusDeProjet,
      date: projet.node.date,
      image: projet.node.featuredImage?.node.mediaItemUrl,
    });
  });
  return allprojet;
};
const ProjectPage: FC = () => {
  const { loading, error, data } = useQuery(GET_ALL_PROJET);

  const projets = data ? getAllAProjets(data.projets.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div>
      <div className="projetSection">
        {projets?.map(
          (projet: any, i: number) =>
            projet.status !== "termine" && (
              <div style={{ justifySelf: "center" }}>
                <ProjetComponent cName="page" title={projet.title} />
              </div>
            )
        )}
      </div>
      <h2 className="projetTermineTitle"> Grâce à vous, c'est fait!</h2>
      <div className="projetSection">
        {projets?.map(
          (projet: any, i: number) =>
            projet.status === "termine" && (
              <div key={i} className="cardProjetTermine">
                <img src={projet?.image} alt={projet.id} />
                <div className="cardProjetTermineBody">
                  <h3 id="projetName">{projet.title}</h3>
                  {projet.body && <p> {projet.body} </p>}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ProjectPage;

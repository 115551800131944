import { FC, useState } from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import Accueil from "./pags/Accueilpage/Accueil";
import ActivitePage from "./pags/Activitespage/ActivitePage";
import ProjectPage from "./pags/ProjectPage/ProjectPage";
import MerciPage from "./pags/MerciPage/MerciPage";
import AcutaliteDetail from "./pags/AcutalitePage/AcutaliteDetail";
import AcutalitePage from "./pags/AcutalitePage/AcutalitePage";
import Membre from "./pags/Membre/Membre";
import { ActiviteTypeTexonomy } from "./utils/type";
import { GET_ALL_TYPE_ACTIVITE } from "./pags/queries/queries";
import { useQuery } from "@apollo/client";

const getAllTypeActivite = (data: ActiviteTypeTexonomy[]) => {
  if (data.length === 0) {
    return [];
  }
  const allActiviteType: ActiviteTypeTexonomy[] = [];
  data.map((activiteType: any) => {
    allActiviteType.push({
      id: activiteType.node.id,
      name: activiteType.node.name,
      slug: activiteType.node.slug,
      desc: activiteType.node.description,
    });
  });
  return allActiviteType;
};
const App: FC = () => {
  const { loading, error, data } = useQuery(GET_ALL_TYPE_ACTIVITE);
  const activiteType = data ? getAllTypeActivite(data.typeActivites.edges) : [];
  const [footerNavBar, setFooterNavBar] = useState(false);

  const show = () => {
    setFooterNavBar(!footerNavBar);
  };
  return (
    <div className="App">
      <Header allActiviteType={activiteType} />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/activites" element={<ActivitePage slug="" />} />
          {activiteType.map((type: any) => (
            <Route key={Math.random()} path={`/activites/${type.slug}`} element={<ActivitePage slug={type.slug} />} />
          ))}
          <Route path="/on-a-besoin-de-vous" element={<ProjectPage />} />
          <Route path="/actualites/:id" element={<AcutaliteDetail />} />
          <Route path="/actualites" element={<AcutalitePage />}></Route>
          <Route path="/remerciement" element={<MerciPage />} />
          <Route path="/aproposdenous" element={<Membre />} />
        </Routes>
      </div>

      <Footer allActiviteType={activiteType} />
      {/* <iframe
        id="haWidget"
        allowTransparency={true}
        scrolling="auto"
        src="https://www.helloasso.com/associations/aric/formulaires/1/widget"
        style={{ width: '100%', height: "2000px", border: "none" }}
      ></iframe> */}
    </div>
  );
};

export default App;

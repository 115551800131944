import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import "./style.scss";
import { DropdownNav } from "./DropdownNav";
import { ActiviteTypeTexonomy } from "../../utils/type";
import aric from "../../assets/img/aricLogo.png";

type Props = { allActiviteType: ActiviteTypeTexonomy[] };
export const NavbarLink: FC<Props> = ({ allActiviteType }) => {
  const [activiteOpen, setActiviteOpen] = useState(false);

  const showActivite = () => {
    setActiviteOpen(!activiteOpen);
  };
  return (
    <nav>
      <img className="logo" src={aric} />
      <div className="nav">
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/">
          accueil
        </NavLink>
        <li className={window.location.href.includes("activites") ? "active" : ""}>
          <DropdownNav activiteOpen={activiteOpen} showActivite={showActivite} allActiviteType={allActiviteType} />
          <svg
            className="customSvg"
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.86204 10.0002C4.60004 10.0002 4.33904 9.89825 4.14304 9.69525L0.28004 5.69525C-0.0979602 5.30225 -0.0929602 4.67925 0.29304 4.29325L4.29304 0.29325C4.68304 -0.09775 5.31604 -0.09775 5.70704 0.29325C6.09704 0.68425 6.09704 1.31625 5.70704 1.70725L2.40204 5.01225L5.58104 8.30525C5.96504 8.70325 5.95404 9.33625 5.55704 9.71925C5.36204 9.90725 5.11204 10.0002 4.86204 10.0002Z"
              fill="#2C2D41"
            />
          </svg>
        </li>

        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/on-a-besoin-de-vous">
          ON A BESOIN DE VOUS
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/actualites">
          actualités
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/remerciement">
          remerciements
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/aproposdenous">
          à propos de nous
        </NavLink>
        <a href="https://www.helloasso.com/associations/aric/formulaires/1/widget" target="_blank" rel="noreferrer">
          <button>faire un don</button>
        </a>
      </div>
    </nav>
  );
};

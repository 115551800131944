import { FC } from "react";
import Slider from "react-slick";
import Actualite from "./Actualite";
import "./style.scss";
import Spinner from "../spinner/Spinner";
import { ActualiteType } from "../../utils/type";
import { useQuery } from "@apollo/client";
import { GET_ALL_ACTUALITE } from "../../pags/queries/queries";

type Props = {
  settings: any;
};
const getAllActualite = (data: ActualiteType[]) => {
  if (data.length === 0) {
    return [];
  }
  const allActualite: ActualiteType[] = [];
  data.map((actualite: any) => {
    allActualite.push({
      id: actualite.node.databaseId,
      title: actualite.node.title,
      body: actualite.node.content,
      challenge: actualite.node.challenge,
      lienDeDon: actualite.node.lienDon,
      date: actualite.node.date,
      author: actualite.node.author.node.name,
      image: actualite.node.featuredImage?.node?.mediaItemUrl,
    });
  });
  return allActualite;
};
const ActualiteSection: FC<Props> = ({ settings }) => {
  const { loading, error, data } = useQuery(GET_ALL_ACTUALITE);

  const actualites = data ? getAllActualite(data.actualites.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div className="mySlider">
      <Slider {...settings}>
        {actualites?.slice(0, 9).map((actualite: any, i: number) => (
          <Actualite
            key={Math.random()}
            id={actualite.id}
            title={actualite.title}
            date={actualite.date}
            url={actualite.image}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ActualiteSection;

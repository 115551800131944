import { gql } from "@apollo/client";

const MAX_NUM_RESOURCES = 8;

export const GET_ALL_ACTUALITE = gql`
  query NewQuery {
    actualites(first: 500) {
      edges {
        node {
          databaseId
          title
          content
          challenge
          lienDon
          date
          dateGmt
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_ACTIVITE = gql`
  query NewQuery {
    activites(first: 500) {
      edges {
        node {
          databaseId
          title
          content
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          typeActivites {
            nodes {
              slug
              name
              description
            }
          }
          date
        }
      }
    }
  }
`;
export const GET_ALL_PROJET = gql`
  query NewQuery {
    projets(first: 500) {
      edges {
        node {
          databaseId
          title
          content
          statusDeProjet
          date
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_DONATEUR = gql`
  query NewQuery {
    donateurs(first: 500) {
      edges {
        node {
          databaseId
          nom
          detail
          dateGmt
        }
      }
    }
  }
`;

export const GET_ALL_ACTUALITE_BY_ID = gql`
  query NewQuery($id: [ID]) {
    actualites(where: { in: $id }) {
      edges {
        node {
          databaseId
          title
          content
          challenge
          lienDon
          date
          dateGmt
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_TYPE_ACTIVITE = gql`
  query NewQuery {
    typeActivites {
      edges {
        node {
          id
          name
          slug
          description
        }
      }
    }
  }
`;

export const GET_TYPE_BY_SLUG = gql`
  query NewQuery($slug: [SLUG]) {
    typeActivites(where: { slug: $slug }) {
      edges {
        node {
          name
          id
          slug
          description
        }
      }
    }
  }
`;

export const GET_ACTIVITE_BY_TYPE = gql`
  query NewQuery($slug: String) {
    activites {
      edges {
        node {
          databaseId
          title
          content
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          typeActivites(where: { slug: $slug }) {
            nodes {
              slug
              name
              description
            }
          }
          date
        }
      }
    }
  }
`;
export const GET_ALL_MEMBERS = gql`
  query NewQuery {
    members {
      edges {
        node {
          nom
          occupant
          databaseId
        }
      }
    }
  }
`;

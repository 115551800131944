import { FC } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_ALL_ACTUALITE_BY_ID } from "../queries/queries";
import { ActualiteType } from "../../utils/type";
export function getAllActualite(data: any): ActualiteType {
  if (data.actualites.edges.length === 0) {
    return {} as ActualiteType;
  }
  const actualite = data.actualites.edges[0].node;

  return {
    id: actualite.databaseId,
    title: actualite.title,
    body: actualite.content,
    challenge: actualite.challenge,
    lienDeDon: actualite.lienDon,
    date: actualite.date,
    author: actualite.author.name,
    image: actualite.featuredImage?.node.mediaItemUrl,
  };
}

const AcutaliteDetail: FC = () => {
  const { id } = useParams<any>();

  const result = useQuery(GET_ALL_ACTUALITE_BY_ID, { variables: { id: id } });

  moment.locale("fr"); // default the locale to English
  function formatMyDate(value: string, locale = "fr-Fr") {
    return new Date(value).toLocaleDateString(locale, { year: "numeric", month: "short", day: "numeric" });
  }

  const actualite = result.data ? getAllActualite(result.data) : ({} as ActualiteType);

  return (
    <div className="acutaliteDetail">
      {actualite && (
        <>
          <div className="header">
            <h2>{actualite.title}</h2>
          </div>
          <span> {formatMyDate(actualite.date)}</span>
          <div style={{ paddingBottom: "15px" }}></div>
          {actualite.image && <img className="featuredImg" src={actualite.image} alt="actuel" />}
          <div className="body" dangerouslySetInnerHTML={{ __html: actualite.body }}></div>
          {actualite.lienDeDon && (
            <a className="challenge" href={actualite.lienDeDon} rel="noreferrer">
              Faire un don
            </a>
          )}
          {/* {actualite.image && <img src={actualite.image} alt="actuel" />}
            {actualite.body && (
              <div>
                {" "}
                <ReactMarkdown children={actualite.body} />{" "}
                {actualite.lienDeDon && (
                  <a className="challenge" href={actualite.lienDeDon} rel="noreferrer">
                    Faire un don
                  </a>
                )}
              </div>
            )} */}
        </>
      )}
    </div>
  );
};

export default AcutaliteDetail;

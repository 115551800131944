import { FC } from "react";
import "./style.scss";
import cx from "classnames";
import Spinner from "../spinner/Spinner";
import { useQuery } from "@apollo/client";
import { GET_ALL_TYPE_ACTIVITE } from "../../pags/queries/queries";
import { ActiviteTypeTexonomy } from "../../utils/type";

const getAllTypeActivite = (data: ActiviteTypeTexonomy[]) => {
  if (data.length === 0) {
    return [];
  }
  const allActiviteType: ActiviteTypeTexonomy[] = [];
  data.map((activiteType: any) => {
    allActiviteType.push({
      id: activiteType.node.id,
      name: activiteType.node.name,
      slug: activiteType.node.slug,
      desc: activiteType.node.description,
    });
  });
  return allActiviteType;
};
const Activite: FC = () => {
  const { loading, error, data } = useQuery(GET_ALL_TYPE_ACTIVITE);
  const activiteType = data ? getAllTypeActivite(data.typeActivites.edges) : [];
  const allLowerCase = (str: any) => {
    return str?.toLowerCase().substring(0, 3);
  };
  const path = (str: any) => {
    const newPath = str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return newPath?.toLowerCase().replace(/ /g, "");
  };
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div className="section_activites">
      {activiteType?.map((type: any, i: number) => (
        <div
          key={i}
          className={cx("cardActivite", allLowerCase(type.slug))}
          onClick={() => window.open("/activites/" + path(type.slug))}
        >
          <h3 id="actualiteName">{type.name}</h3>
          <div>{type.desc}</div>
        </div>
      ))}
    </div>
  );
};

export default Activite;

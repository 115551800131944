import React, { FC, useRef, useState,useEffect } from "react";
import "./style.scss";
import emailjs from "@emailjs/browser";


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_un6lm1l", "template_3wlm83n", form.current, "Y6YV5r43XoI3H5pHl").then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
          window.location.reload(false);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
      <div className="contactUsSection">

      <form ref={form} onSubmit={sendEmail} className="contact">
        <div>
     <div>
          <label>Nom Prénom</label>
          <br />
          <input type="text" name="user_name" placeholder="Nom" />
        </div>
        <div>
          <label>Email</label>
          <br />
          <input type="email" name="user_email" placeholder="Email" />
        </div>
        </div>
   
      <div>
        <label>Message</label>
        <br />
        <textarea name="message"></textarea>
        </div>

      </form>
        <button className="submitButton" type="submit">Envoyez</button>


      </div>
      
  );
};

export default Contact;

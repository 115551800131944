import { FC } from "react";
import "./style.scss";
import aric from "../../assets/img/aric.png";
type Props = {
  id: number;
  title: string;
  date: string;
  url: string;
};

function formatMyDate(value: string, locale = "fr-Fr") {
  return new Date(value).toLocaleDateString(locale, { year: "numeric", month: "short", day: "numeric" });
}
const Actualite: FC<Props> = ({ id, title, date, url }) => {
  return (
    <>
      <div className="cardActualite" onClick={() => window.open("/actualites/" + id)} key={id}>
        {url ? <img src={url} alt="actuel" /> : <img src={aric} alt="actuel" />}
        <div>
          <p>{title}</p>
          {/* <span> {formatMyDate(date)} </span> */}
        </div>
      </div>
    </>
  );
};

export default Actualite;

import { FC } from "react";
import "./style.scss";
import Slider from "react-slick";
import Merci from "./Merci";
import Spinner from "../spinner/Spinner";
import { useQuery } from "@apollo/client";
import { GET_ALL_DONATEUR } from "../../pags/queries/queries";
import { Donateur } from "../../utils/type";

type Props = {
  settings: any;
};

const getAllDonateurs = (data: Donateur[]) => {
  if (data.length === 0) {
    return [];
  }
  const alldonateurs: Donateur[] = [];
  data.map((donateur: any) => {
    alldonateurs.push({
      id: donateur.node.databaseId,
      name: donateur.node.nom,
      desc: donateur.node.detail,
      date: donateur.node.dateGmt,
    });
  });
  return alldonateurs;
};
const MerciSection: FC<Props> = ({ settings }) => {
  const { loading, error, data } = useQuery(GET_ALL_DONATEUR);

  const donateurs = data ? getAllDonateurs(data.donateurs.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <>
      <Slider {...settings}>
        {donateurs?.slice(0, 9).map((merci: any, i: number) => (
          <Merci key={Math.random()} id={merci.id} nom={merci.name} />
        ))}
      </Slider>
    </>
  );
};

export default MerciSection;

import { FC } from "react";
import Spinner from "../../components/spinner/Spinner";
import "./style.scss";
import { Donateur } from "../../utils/type";
import { useQuery } from "@apollo/client";
import { GET_ALL_DONATEUR } from "../queries/queries";

const getAllDonateurs = (data: Donateur[]) => {
  if (data.length === 0) {
    return [];
  }
  const alldonateurs: Donateur[] = [];
  data.map((donateur: any) => {
    alldonateurs.push({
      id: donateur.node.databaseId,
      name: donateur.node.nom,
      desc: donateur.node.detail,
      date: donateur.node.dateGmt,
    });
  });
  return alldonateurs;
};
const MerciPage: FC = () => {
  function formatMyDate(value: string, locale = "fr-Fr") {
    return new Date(value).toLocaleDateString(locale, { year: "numeric" });
  }
  const { loading, error, data } = useQuery(GET_ALL_DONATEUR);
  const donateurs = data ? getAllDonateurs(data.donateurs.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div className="merciPage">
      <h2>NOS GENEREUX DONATEURS</h2>
      <p style={{ paddingBottom: 50, fontWeight: 500, fontSize: "20px", lineHeight: "22px" }}>
        Merci pour vos dons. Nous souhaitons remercier très chaleureusement toutes les personnes qui régulièrement font
        preuve de générosité et de soutien envers l’ARIC.
      </p>
      {donateurs?.map((merci: any, i: number) => (
        <div className="remerciement">
          <div className="heart">
            <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.3335 26.7581C15.6043 27.5263 14.4064 27.5263 13.6772 26.7581L2.34387 14.8622C-0.958214 11.4143 -0.74988 5.68306 2.83345 2.49348C6.12512 -0.293396 10.9168 0.211709 13.8439 3.29765L14.9168 4.5091L16.0782 3.30223C19.0105 0.216396 23.7887 -0.288812 27.0053 2.49765C30.6897 5.68515 30.8751 11.4122 27.5939 14.8622L16.3335 26.7581Z"
                fill="#ED4C67"
              />
            </svg>
          </div>

          <div className="detail">
            <h5>{formatMyDate(merci.date)}</h5>
            <p>{merci.desc}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MerciPage;

import { FC } from "react";
import moment from "moment";
import Activite from "../../components/activite/Activite";
import ActualiteSection from "../../components/actualite/ActualiteSection";
import MerciSection from "../../components/merci/MerciSection";
import ProjetSection from "../../components/projet/ProjetSection";

import "./style.scss";
import Contact from "../../components/contact/Contact";

const Accueil: FC = () => {
  moment.locale("fr"); // default the locale to English

  return (
    <div className="accueil">
      <p className="accueilText">
        Ce site vous présente les actions et projets du service enfants-adolescents de l’Institut Universitaire de
        Réadaptation Clemenceau. De nombreuses activités sont uniquement rendues possibles grâce à la participation
        financière de l’association ARIC. L’ARIC, Association pour la Réadaptation Infantile à Clemenceau a été créée en
        septembre 2006 par des professionnels du service de rééducation enfants. L’Association propose de financer pour
        les enfants hospitalisés les activités sportives, éducatives, culturelles. Il s’agit par ce biais de veiller à
        l’épanouissement des jeunes et à leur ouverture au monde extérieur malgré leur maladie ou leur situation de
        handicap.
      </p>
      {/* <div className="challengeActuel">
        {" "}
        {data ? (
          <>
            <div className="header">
              <h2>{data.title}</h2>
            </div>
            <div className="body">
              {data.image && (
                <img src={process.env.REACT_APP_API_URL + data.image?.data[0].attributes?.url} alt="actuel" />
              )}
              {data.body && (
                <div>
                  {" "}
                  <ReactMarkdown children={data.body} />{" "}
                  {data.faireDon && (
                    <a className="challenge" href={data.faireDon} rel="noreferrer">
                      Faire un don
                    </a>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div> */}
      <div>
        <h2>activités</h2>
        <Activite />
      </div>
      <div className="section_projet">
        <div className="header">
          <h2>Projets</h2>
          <div className="seemore">
            <a className="" href="/on-a-besoin-de-vous">
              Voir plus
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.86204 10.0002C4.60004 10.0002 4.33904 9.89825 4.14304 9.69525L0.28004 5.69525C-0.0979602 5.30225 -0.0929602 4.67925 0.29304 4.29325L4.29304 0.29325C4.68304 -0.09775 5.31604 -0.09775 5.70704 0.29325C6.09704 0.68425 6.09704 1.31625 5.70704 1.70725L2.40204 5.01225L5.58104 8.30525C5.96504 8.70325 5.95404 9.33625 5.55704 9.71925C5.36204 9.90725 5.11204 10.0002 4.86204 10.0002Z"
                  fill="#2C2D41"
                />
              </svg>
            </a>
          </div>
        </div>
        <ProjetSection settings={settings} />
      </div>
      <div className="section_actualite">
        <div className="header">
          <h2>Actualités</h2>
          <div className="seemore">
            <a className="" href="/actualites">
              Voir plus
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.86204 10.0002C4.60004 10.0002 4.33904 9.89825 4.14304 9.69525L0.28004 5.69525C-0.0979602 5.30225 -0.0929602 4.67925 0.29304 4.29325L4.29304 0.29325C4.68304 -0.09775 5.31604 -0.09775 5.70704 0.29325C6.09704 0.68425 6.09704 1.31625 5.70704 1.70725L2.40204 5.01225L5.58104 8.30525C5.96504 8.70325 5.95404 9.33625 5.55704 9.71925C5.36204 9.90725 5.11204 10.0002 4.86204 10.0002Z"
                  fill="#2C2D41"
                />
              </svg>
            </a>
          </div>
        </div>
        <ActualiteSection settings={settings} />
      </div>
      <div className="section_merci">
        <div className="header">
          <h2>Remerciements</h2>
          <div className="seemore">
            <a className="" href="/remerciement">
              Voir plus{" "}
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.86204 10.0002C4.60004 10.0002 4.33904 9.89825 4.14304 9.69525L0.28004 5.69525C-0.0979602 5.30225 -0.0929602 4.67925 0.29304 4.29325L4.29304 0.29325C4.68304 -0.09775 5.31604 -0.09775 5.70704 0.29325C6.09704 0.68425 6.09704 1.31625 5.70704 1.70725L2.40204 5.01225L5.58104 8.30525C5.96504 8.70325 5.95404 9.33625 5.55704 9.71925C5.36204 9.90725 5.11204 10.0002 4.86204 10.0002Z"
                  fill="#2C2D41"
                />
              </svg>
            </a>
          </div>
        </div>
        <MerciSection settings={settings} />
      </div>
      <div className="section_contact">
        <div className="header">
          <h2>Contact</h2>
        </div>
        <Contact />
      </div>
    </div>
  );
};

const settings = {
  infinite: false,
  speed: 800,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  cssEase: "linear",
  adaptiveHeight: true,
  centerMode: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1004,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
        arrows: true,
      },
    },

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
};
export default Accueil;

import { FC } from "react";
import "./style.scss";
import Spinner from "../../components/spinner/Spinner";
import TabPanel from "../../components/TabPanel";
import { GET_ACTIVITE_BY_TYPE, GET_ALL_ACTIVITE } from "../queries/queries";
import { useQuery } from "@apollo/client";
import { ActiviteType } from "../../utils/type";

const getAllTypeActivite = (data: ActiviteType[]) => {
  if (data.length === 0) {
    return [];
  }
  const allActivite: ActiviteType[] = [];
  data.map((activite: any) => {
    allActivite.push({
      id: activite.node.id,
      title: activite.node.title,
      body: activite.node.content,
      image: "",
      category: activite.node.typeActivites.nodes.map((cat: any) => {
        return {
          name: cat.name,
          slug: cat.slug,
          desc: cat.description,
        };
      }),
      date: "",
    });
  });
  return allActivite;
};
type Props = {
  slug: string;
};
const ActivitePage: FC<Props> = ({ slug }) => {
  const { loading, error, data } = useQuery(GET_ALL_ACTIVITE);
  const activiteType = data ? getAllTypeActivite(data.activites.edges) : [];

  const filtered = activiteType.filter((cl) => cl.category.some((c) => c.slug === slug));
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="activitePage">
      <div>
        {filtered?.map((activite: any, i: number) => (
          <div key={i} className="cardActivite">
            <div>
              <h3 id="actualiteName">{activite.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: activite.body }}></div>
            </div>
            {/* <div>
              <img src={activite.image} alt="actuel" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivitePage;

import { FC } from "react";
import cx from "classnames";
import "./style.scss";

type Props = {
  title: string;
  cName?: string;
};

const Projet: FC<Props> = ({ title, cName }) => {
  return (
    <div className={cx("projet", cName)} onClick={() => window.open("/on-a-besoin-de-vous")}>
      <p className="projetTitle">{title}</p>
    </div>
  );
};

export default Projet;

import { FC } from "react";
import "./style.scss";
import Actualite from "../../components/actualite/Actualite";
import Spinner from "../../components/spinner/Spinner";
import { GET_ALL_ACTUALITE } from "../queries/queries";
import { useQuery } from "@apollo/client";
import { ActualiteType } from "../../utils/type";

const getAllActualite = (data: ActualiteType[]) => {
  if (data.length === 0) {
    return [];
  }
  const allActualite: ActualiteType[] = [];
  data.map((actualite: any) => {
    allActualite.push({
      id: actualite.node.databaseId,
      title: actualite.node.title,
      body: actualite.node.content,
      challenge: actualite.node.challenge,
      lienDeDon: actualite.node.lienDon,
      date: actualite.node.date,
      author: actualite.node.author.node.name,
      image: actualite.node.featuredImage?.node.mediaItemUrl ? actualite.node.featuredImage?.node.mediaItemUrl : "",
    });
  });
  return allActualite;
};

const AcutalitePage: FC = () => {
  // const { data, isLoading, error } = useQuery(["actualite"], async () => getActualite());
  const { loading, error, data } = useQuery(GET_ALL_ACTUALITE);

  const actualites = data ? getAllActualite(data.actualites.edges) : [];

  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <div className="acutalitePage">
      {actualites?.map((actualite: any, i: number) => (
        <Actualite id={actualite.id} title={actualite.title} date={actualite.date} url={actualite.image} />
      ))}
    </div>
  );
};

export default AcutalitePage;

import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import "./style.scss";
import { DropdownNav } from "./DropdownNav";
import { ActiviteTypeTexonomy } from "../../utils/type";
import aric from "../../assets/img/aricLogo.png";

type Props = { allActiviteType: ActiviteTypeTexonomy[] };
export const NavbarLinkMobile: FC<Props> = ({ allActiviteType }) => {
  const [activiteOpen, setActiviteOpen] = useState(false);

  const showActivite = () => {
    setActiviteOpen(!activiteOpen);
  };
  return (
    <nav>
      <img className="logo" src={aric} />
      <div className="nav">
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/">
          accueil
        </NavLink>
        {allActiviteType.map((type: any) => (
          <NavLink
            key={Math.random()}
            reloadDocument
            className={({ isActive }) => cx(isActive ? "active" : "")}
            to={`/activites/${type.slug}`}
          >
            {type.name}
          </NavLink>
        ))}

        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/on-a-besoin-de-vous">
          ON A BESOIN DE VOUS
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/actualites">
          actualités
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/remerciement">
          remerciements
        </NavLink>
        <NavLink reloadDocument className={({ isActive }) => cx(isActive ? "active" : "")} to="/aproposdenous">
          à propos de nous
        </NavLink>
        <a href="https://www.helloasso.com/associations/aric/formulaires/1/widget" target="_blank" rel="noreferrer">
          <button>faire un don</button>
        </a>
      </div>
    </nav>
  );
};

import { FC } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import "./style.scss";
import { ActiviteTypeTexonomy } from "../../utils/type";
type Props = {
  showActivite: any;
  activiteOpen: boolean;
  allActiviteType: ActiviteTypeTexonomy[];
};
export const DropdownNav: FC<Props> = ({ showActivite, activiteOpen, allActiviteType }) => {
  return (
    <div className="dropdownLink">
      <div className={cx("header-menu", activiteOpen ? "bground" : "")} onClick={showActivite}>
        Activites
      </div>
      <div className={cx("dropdownBody", activiteOpen ? "show-menu" : "hide-menu")} onClick={showActivite}>
        {allActiviteType.map((type: any) => (
          <NavLink
            key={Math.random()}
            reloadDocument
            className={({ isActive }) => cx(isActive ? "active" : "")}
            to={`/activites/${type.slug}`}
          >
            {type.name}
          </NavLink>
        ))}
        {/* <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/terrasse">
          Terrasse
        </NavLink>
        <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/musique">
          Musique
        </NavLink>
        <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/sortie">
          Sorties
        </NavLink>
        <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/plongee">
          Plongée
        </NavLink>
        <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/activitesmanuelles">
          Activités manuelles
        </NavLink>
        <NavLink className={({ isActive }) => cx(isActive ? "active" : "")} to="/activites/technologie">
          Technologie
        </NavLink> */}
      </div>
    </div>
  );
};

import { FC } from "react";
import { NavbarLink } from "./NavbarLink";
import cx from "classnames";
import "./style.scss";
import { ActiviteTypeTexonomy } from "../../utils/type";
import { NavbarLinkMobile } from "./NavbarLinkMobile";
type Props = {
  show: any;
  navBarOpen: boolean;
  allActiviteType: ActiviteTypeTexonomy[];
};
export const Navbar: FC<Props> = ({ show, navBarOpen, allActiviteType }) => {
  return (
    <div className="navbar">
      <div className="desktopNav">
        <div className="desktopLine"></div>
        <div className="desktopBar">
          <NavbarLink allActiviteType={allActiviteType} />
        </div>
      </div>
      <div className="mobileNavButton">
        <div className={cx("header-menu", navBarOpen ? "bground" : "")} onClick={show}>
          <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 10H28V12H10V10Z" fill="#2C2D41" />
            <path d="M10 16H28V18H10V16Z" fill="#2C2D41" />
            <path d="M10 22H28V24H10V22Z" fill="#2C2D41" />
          </svg>
        </div>

        <div className={cx("mobileNav", navBarOpen ? "show-menu" : "hide-menu")} onClick={show}>
          <NavbarLinkMobile allActiviteType={allActiviteType} />
        </div>
      </div>
    </div>
  );
};

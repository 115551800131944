import { FC } from "react";
import heart from "../../assets/img/merci.svg";
import "./style.scss";

type Props = {
  id?: number;
  nom: string;
};

const Merci: FC<Props> = ({ nom, id }) => {
  return (
    <div>
      <div className="merci">
        <img src={heart} alt="merciicon" />
        <p>{nom}</p>
      </div>
    </div>
  );
};

export default Merci;

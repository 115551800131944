import { FC } from "react";

import "./style.scss";
import Slider from "react-slick";
import Spinner from "../spinner/Spinner";
import { Projet } from "../../utils/type";
import { GET_ALL_PROJET } from "../../pags/queries/queries";
import { useQuery } from "@apollo/client";
import ProjetComponent from "./Projet";
type Props = {
  settings: any;
};
const getAllAProjets = (data: Projet[]) => {
  if (data.length === 0) {
    return [];
  }
  const allprojet: Projet[] = [];
  data.map((projet: any) => {
    allprojet.push({
      id: projet.node.databaseId,
      title: projet.node.title,
      body: projet.node.content,
      status: projet.node.typeActivite,
      date: projet.node.date,
    });
  });
  return allprojet;
};
const ProjetSection: FC<Props> = ({ settings }) => {
  const { loading, error, data } = useQuery(GET_ALL_PROJET);

  const projets = data ? getAllAProjets(data.projets.edges) : [];
  if (loading) {
    return <Spinner />;
  }
  if (error) return null;
  return (
    <>
      <Slider className="projetSlider" {...settings}>
        {projets
          ?.slice(0, 9)
          .map(
            (projet: any, i: number) =>
              projet.status !== "termine" && <ProjetComponent key={Math.random()} title={projet.title} />
          )}
      </Slider>
    </>
  );
};

export default ProjetSection;
